
.Login-header {
  padding: 10px;
  background-color: lightblue;
  height: 30px;
  margin: 0px;
}

/* .Login-header * {
  margin-right: 10px;
} */

.Main {
  margin: 0px;
  padding: 0px;
  display: block;
  background-color: lightgray;
  color: black;
}

.MainContent {
  background-color: lightgray;
  margin: 10px;
}

.MainContent h1 {
  font-size: 20px;
  font-weight: normal;
}

.MainContent * {
  margin-right: 10px;
}