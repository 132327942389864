
.StateOCRContent {
    display: flex;
  }
  
  .SelectedActionsBar {
    position: sticky;
    top: 0px;
    text-align: center;
    background-color: inherit;
    z-index: 2;
    margin: 8px;
    padding: 8px;
  }
  
  .SelectedActionsBar * {
    padding: 8px;
    vertical-align: middle;
  }
  
  .StateOCR-Search {
    text-align: center;
  }
  
  .StateOCR-Search * {
    display: inline-block;
    vertical-align: middle;
  }
  
  .StateOCR-Item {
    margin-right: 10px;
    margin-top: 10px;
    display: inline-block;
    width: 300px;
    height: 200px;
    background-color: aliceblue;
    text-align: center;
  }
  
  .StateOCR-Results {
    display: inline-block;
    text-align: center;
    width: 100%;
  }
  
  .Dropdown {
    width: 300px;
  }
  
  .Box-Normal {
    margin: 8px;
    padding: 8px;
    padding-top: 0px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 16px;
    width: 300px;
    text-align: 'center';
    display: inline-block;
    position: relative;
    transition: 1s;
    z-index: 0;
  }
  
  .Box-Normal-Selected {
    background-color: rgba(164, 190, 255, 0.202);
    transition: 0.5s;
  }
  
  .Box-Anim-Success {
    animation: border-flickering 0.8s;
  }
  
  @keyframes border-flickering {
    0% { border-color: black;}
    50% { border-color: green; background-color: rgb(147, 189, 148);}
    100% { border-color: black; background-color: none;}
  }


  .EditorMain {
    position: fixed;
    inset: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.479);
    z-index: 2;
  }

  .EditorContent {
    position: absolute;
    inset: 50px;
    background-color: white;
    /* z-index: 40; */
  }